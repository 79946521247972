import React, {useState} from 'react';
import AppointmentsTable from '../Components/AppointmentsTable/AppointmentsTable';
import Nav from '../Components/Nav/Nav';
import LookerDashboard from '../Components/LookerDashboard/LookerDashboard';

const Main = () => {
    const [tab, setTab] = useState('outcome');

    const changeTab = (value) =>{
        setTab(value)
    }

  return (
    <div className="App">
        <Nav tab={tab} changeTab={changeTab}/>
        {tab === 'outcome' ? (
            <AppointmentsTable />
        ) : tab === 'report' ? (
            <LookerDashboard />
        ) : null}
    </div>
  );
};

export default Main;
