import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './appointmentsTable.css'
import React, { useState, useEffect,useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { allAppointmentsUrl, appointmentOutcomeUrl } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

export default function AppointmentsTable() {
    const {locationId} = useParams()
    const url = `${allAppointmentsUrl}${locationId}`
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [visible, setVisible] = useState(false);
    const [outcome, setOutcome] = useState('');
    const [outcomeValue, setOutcomeValue] = useState('');
    const [notes, setNotes] = useState('');
    const [isMounted, setIsMounted] = useState(false)
    const [id, setId] = useState('')
    const iconsAndText = [
        { icon: 'pi pi-check-circle', text: 'Sale', value: 'Sale', color:'green'},
        { icon: 'pi pi-times', text: 'No Sale', value: 'No Sale', color:'yellow' },
        { icon: 'pi pi-shield', text: 'Value/Coaching', value: 'Value/Coaching', color:'purple' },
        { icon: 'pi pi-pencil', text: 'Reschedule', value: 'Reschedule', color:'blue' },
        { icon: 'pi pi-calendar-times', text: 'Did Not Show', value: 'noshow', color:'red' },
        // { icon: 'pi pi-hourglass', text: 'Said they are still thinking', value: 'showed' },
        // { icon: 'pi pi-question-circle', text: 'Something else', value: 'showed' },
        // { icon: 'pi pi-clock', text: 'Started a trial', value: 'showed' },
      ];
    
    const generateDateRange = () => {
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);

    return [lastMonth, today];
    };
    const [dates, setDates] = useState(() => generateDateRange());
    const [selectedDateType, setSelectedDateType] = useState({ name: 'Appointment Timing', code: 'BD' });
    const DateType = [
        { name: 'Appointment Booked On', code: 'BO' },
        { name: 'Appointment Timing', code: 'BD' }
    ];
    const toast = useRef(null);

      const handleCloseDialog = () =>{
        setVisible(false)
        setOutcome('')
        setNotes('')
        setOutcomeValue('')
      }
    
    useEffect(()=>{
        initFilters();
    }, [])

    function formatUrlDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${year}-${month}-${day}`;
    }

    useEffect(()=>{
        if(dates){

            const areBothDatesNotNull = dates.every(date => date !== null);
            if (areBothDatesNotNull){

                const dateString1 = formatUrlDate(dates[0]);
                const dateString2 = formatUrlDate(dates[1]);
                setLoading(true)
                axios.get(`${url}?startDate=${dateString1}&endDate=${dateString2}&type=${selectedDateType.name}`, {
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setCustomers(getCustomers(response.data.appointments));
                        setLoading(false);
                    }
                    })
                .catch((error)=>{
                    setCustomers(null)
                    
                })

            }

        }       
    },[url, isMounted, selectedDateType, dates])

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.start_at = new Date(d.start_at);
            d.created_at = new Date(d.created_at);
            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      };

    const formatDateTime = (value) => {
        const formattedDate = formatDate(value);
        const formattedTime = value.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
        return `${formattedDate}    ${formattedTime}`;
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            calendar_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            assigned_user_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            outcome_cf: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            start_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="header-container">
                <div className="flex justify-content-between filter-container">
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>
                </div>

                <div className="filter-container">
                    <Dropdown value={selectedDateType} onChange={(e) => setSelectedDateType(e.value)} options={DateType} optionLabel="name" 
                    placeholder="Select a date feild" className="w-full md:w-14rem" />
                    <span className="p-float-label filter-card-container">
                        <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" className="full-width"/>
                    </span>
                </div>
            </div>
        );
    };

    const dateBookedOnBodyTemplate = (rowData) => {
        const db_outcome = rowData.outcome_cf
        return( 
            <div className={`outcome-${db_outcome}`}>
            {formatDateTime(rowData.created_at)}
            </div>
        )
    };

    const dateBookedAtBodyTemplate = (rowData) => {
        const db_outcome = rowData.outcome_cf
        return(
            <div className={`outcome-${db_outcome}`}>
            {formatDateTime(rowData.start_at)}
            </div>
        )
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const handleopendialog = (id)=>{
        setId(id)
        setVisible(true)
    }

    const handleOutcomeSubmit = () =>{

        if(outcome !== ''){
            const data = {
                'appointment_id': id,
                'outcome': outcome
            }
            axios.post(appointmentOutcomeUrl, data, {
                headers: {'Content-Type': 'application/json' },
            })
            .then((response) => {
                if (response.status === 200) {
                    setVisible(false)
                    setOutcome('')
                    setNotes('')
                    setOutcomeValue('')
                    const index = customers.findIndex(obj => obj.id === id);
                    if (index !== -1) {
                        customers[index].outcome_cf = outcome;
                        console.log("Outcome updated successfully!");
                    } else {
                        console.log("Object with ID", id, "not found in the data.");
                        setIsMounted(!isMounted)
                    }
                    toast.current.show({severity:'success', summary: 'Success', detail:'Record Submitted', life: 2000});
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
                }
                })
            .catch((error)=>{
                toast.current.show({severity:'error', summary: 'Error', detail:error.response.data, life: 2000});
            })
        }
        else{
            toast.current.show({severity:'warn', summary: 'Warning', detail:'Please select an outcome', life: 2500});
        }
    }

    const outcomeBodyTemplate = (rowData) => {
        const db_outcome = rowData.outcome_cf

        return (
        <div className="flex justify-content-center">
        <Toast ref={toast} />
        <Button className="set-outcome-button" icon="pi pi-ellipsis-h" onClick={() => handleopendialog(rowData.id)}/>
        <Dialog header="HOW DID THE APPOINTMENT GO?" className="outcome-dialog" visible={visible} onHide={() => handleCloseDialog()}>
        <div className="modal-content">
        {iconsAndText.map((item, index) => (
            <div className={`rectangle-box-${item.color}`} key={item.text} onClick={() => {setOutcome(item.text); setOutcomeValue(item.value)}}>
              <div className={`box-logo-${item.color}`}>
                <span className={`pi ${item.icon} outcome-icon`}></span>
              </div>
              <div className={`box-content ${outcome === item.text ? `box-content-selected-${item.color}` : ''}`}>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        {/* <div className="callNotes-container">
            <h3 className="callNotes-heading">Appointment notes</h3>
            <InputTextarea placeholder="Any notes about this appointment?" value={notes} onChange={(e) => setNotes(e.target.value)} rows={5} cols={30} style={{width:'100%'}}/>
        </div> */}
        <div className="submit-buttons-container">
        <Button className="cancel-button" label="Cancel" onClick={() => handleCloseDialog()} />
        <Button className="record-button" label="Update Outcome" icon="pi pi-phone" onClick={handleOutcomeSubmit}/>
        </div>
        </div>
        </Dialog>
        </div>
        )};

    const redirectBody = (rowData) => {

        return(
            <div className="flex justify-content-center">
                <Button className="set-redirect-button" icon="pi pi-external-link" onClick={() => handleRedirect(rowData.contact_id)}/>
            </div>
        )
    }
    

    const handleRedirect = (contactId) => {
        // window.location.href = `https://app.supafitgrow.com/v2/location/${locationId}/contacts/detail/${contactId}`; 
        const url = `https://app.resultsdrivenrei.com/v2/location/${locationId}/contacts/detail/${contactId}`;
        window.open(url, '_blank');
      };
    

    const outcomeBody = (rowData) => {
        const db_outcome = rowData.outcome_cf

        return(
            <div className={`outcome-${db_outcome}`}>
                {db_outcome? <span>{db_outcome}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const nameBody = (rowData) => {
        const db_outcome = rowData.outcome_cf
        const name = rowData.full_name
        return(
            <div className={`outcome-${db_outcome}`}>
                {name? <span>{name}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const lastNameBody = (rowData) => {
        const db_outcome = rowData.outcome_cf
        const lastName = rowData.last_name
        return(
            <div className={`outcome-${db_outcome}`}>
                {lastName? <span>{lastName}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const emailBody = (rowData) => {
        const db_outcome = rowData.outcome_cf
        const email = rowData.email
        return(
            <div className={`outcome-${db_outcome}`}>
                {email? <span>{email}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const phoneBody = (rowData) => {
        const db_outcome = rowData.outcome_cf
        const phone = rowData.phone
        return(
            <div className={`outcome-${db_outcome}`}>
                {phone? <span>{phone}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const calendarNameBody = (rowData) => {
        const db_outcome = rowData.outcome_cf
        const Calendar_Name = rowData.calendar_name
        return(
            <div className={`outcome-${db_outcome}`}>
                {Calendar_Name? <span>{Calendar_Name}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const appointmentUserNameBody = (rowData) => {
        const db_outcome = rowData.outcome_cf
        const appointment_user_name = rowData.assigned_user_name
        return(
            <div className={`outcome-${db_outcome}`}>
                {appointment_user_name? <span>{appointment_user_name}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome_cf
        return <div className={`outcome-${db_outcome}`}>{rowIndex}</div>;
      };

    const header = renderHeader();

    return (
        <div className="card">
            <DataTable value={customers} paginator showGridlines rows={10} loading={loading} dataKey="id" 
                    filters={filters} globalFilterFields={['email', 'phone', 'full_name', 'calendar_name', 'assigned_user_name', 'outcome_cf', 'created_at', 'start_at']} header={header}
                    emptyMessage="No appointments found.">
                <Column header="SR NO" className="padding-0" style={{ minWidth: '2rem'  }} body={srNoBodyTemplate} />
                <Column field="full_name" header="Name" sortable className="padding-0" style={{ minWidth: '9rem' }} body={nameBody}/>
                <Column field="email" header="Email" sortable className="padding-0" style={{ minWidth: '8rem'  }} body={emailBody}/>
                <Column field="phone" header="Phone" sortable className="padding-0" style={{ minWidth: '8rem'  }} body={phoneBody}/>
                <Column header="Appointment Booked On" sortable field="created_at" filterField="created_at" dataType="date" className="padding-0" style={{ minWidth: '14rem' }} body={dateBookedOnBodyTemplate} filter filterElement={dateFilterTemplate} />
                <Column header="Appointment Timing" sortable field="start_at" filterField="start_at" dataType="date" className="padding-0" style={{ minWidth: '14rem' }} body={dateBookedAtBodyTemplate} filter filterElement={dateFilterTemplate} />
                <Column field="assigned_user_name" header="Appointment Booked To" sortable className="padding-0" style={{ minWidth: '14rem'  }} body={appointmentUserNameBody}/>
                <Column field="calendar_name" header="Calandar Name" sortable className="padding-0" style={{ minWidth: '11rem'  }} body={calendarNameBody}/>                    
                <Column header="Outcome" sortable field="outcome_cf" showFilterMatchModes={false} filterMenuStyle={{ minWidth: '4rem' }} className="padding-0" style={{ minWidth: '8rem'  }}
                body={outcomeBody}/>
                <Column field="status" header="Set Outcome" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={outcomeBodyTemplate}/>
                <Column header="Open in CRM" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={redirectBody}/>
            </DataTable>
        </div>
    );
}
