import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './nav.css'
import React from 'react';
import { Button } from 'primereact/button';

const Nav = ({ tab, changeTab }) => {

    return (
      <div className="flex justify-content-between header-flex" style={{margin:'1rem'}}>
          <Button className={tab === 'outcome' ? "nav-button-active" : "nav-button"} type="button" label="Appt Outcome" outlined onClick={() => changeTab('outcome')}/>
          <Button className={tab === 'report' ? "nav-button-active" : "nav-button"} type="button" label="Appt Report"  outlined onClick={() => changeTab('report')}/>
      </div>
    );
  }

export default Nav;