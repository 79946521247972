import React from 'react';

const LookerDashboard = () => {
  return (
    <div className='card' style={{ height: '100vh' }}>
      <iframe
        src="https://lookerstudio.google.com/embed/reporting/f50c56bf-cbc0-4178-ae41-cc6699ed8866/page/68PBE"
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        title="RD - Appt Analysis Report"
      ></iframe>
    </div>
  );
};

export default LookerDashboard;
